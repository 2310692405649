<template>
    <div style="height:100%;">

        <tongji style="height:100%;"></tongji>



    </div>
</template>
  
<script>
export default {
    components: {
        tongji: () => {
            if (window.pmid == 211) {
                return import("../datatj/com/ngz/daka_ngz.vue")
            } else {
                return import("../datatj/com/daka.vue")
            }
        },
    },
    data() {
        return {

        }
    },
    mounted() {


    },
    methods: {


    },

};
</script>
  
